import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import LoadingScreen from './LoadingScreen';
import PageHeader from './boxes/PageHeader';
import PageBody from './boxes/PageBody';
import MainContent from './boxes/MainContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlanCard from './PlanCardComponent';
import clientService from '../services/client';
import Modal from './boxes/Modal';
import { Alert } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import benefits from '../benefits.json';

class PlanPurchase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plans: [],
            cart: [],
            paymentFrequency: [],
            frequency: 'yearly',
            showSwichFrequencyModal: false,
            switchFrequencyValue: '',
            familyOption: [],
            errorMessage: { text: '', id: ''},
            showFamilyOptionModal: false,
            plansExclusionCheckbox: false,
            termsAndConditionsCheckbox: false,
            benefitsCheckbox: false,
            termsAndConditionsCheckbox: false,
            benefitsCheckbox: false,

        };
    }
    componentDidMount() {
        this.setState({
            loading: true
        });

        // make API request to fetch plans
        clientService.getAllPlans(this.props.user.preferences.selectedClient).then((plans) => {
            this.setState({
                plans: plans || [],
                loading: false
            });
        });
    }

    renderLoading() {
        return <LoadingScreen />;
    }


    AddToCart = (item) => {
        const { cart } = this.state;
        let updatedCart = [];
    
        if (item.no_of_enrollees === 0) {
            this.setState({
                errorMessage: {
                    text: 'Add number of enrollees before clicking on the "Add Plan" button',
                    id: item.product_id
                }
            });
            return;
        }
    
        if (
            item.no_of_enrollees > 19 ||
            item.no_of_enrollees +
                cart.reduce((sum, plan) => sum + plan.no_of_enrollees, 0) > 19
        ) {
            this.setState({
                errorMessage: {
                    text: 'A maximum of 19 employees can be added on a small business plan.',
                    id: item.product_id
                }
            });
            return;
        }
    
        const existingCartItemIndex = cart.findIndex(
            (cartItem) => cartItem.product_id === item.product_id
        );
        if (existingCartItemIndex >= 0) {
            // Update the existing item
            const updatedItem = { ...cart[existingCartItemIndex] };
            updatedItem.no_of_enrollees += item.no_of_enrollees;
            updatedItem.price += item.price;
            updatedCart = [...cart];
            updatedCart[existingCartItemIndex] = updatedItem;
        } else {
            // Add a new item to the cart
            updatedCart = [...cart, item];
        }
    
        this.setState({ cart: updatedCart });
    };
    
    removeSingleItem = (id) => {
        this.setState({
            cart: this.state.cart.filter((item) => item.product_id !== id)
        });
    };

    removeAll = () => {
        this.setState({
            cart: []
        });
    };

    showPurchaseModal = () => {
        const enrolleeCount = this.state.cart.reduce((sum, plan) => sum + plan.no_of_enrollees, 0);
        if (enrolleeCount < 3) {
            this.setState({
                errorMessage: {
                    text: 'Small businesses have a minimum of 3 employees',
                    id: ''
                }
            });
            return;
        }
        this.setState({
            showPurchaseModal: true
        });
    };

    hidePurchaseModal = () => {
        this.setState({
            showPurchaseModal: false
        });
    };

    hideSwitchPaymentFrequencyModal = () => {
        this.setState({
            showSwichFrequencyModal: false
        });
    };

    handleChangeFrequency = (frequency) => {
        if (this.state.cart.length > 0) {
            this.setState({
                showSwichFrequencyModal: true,
                switchFrequencyValue: frequency
            });
            return;
        }

        this.setState({
            frequency
        });
    };

    onCheckout = () => {
        const { user } = this.props;
        const paymentFrequency =
            this.state.frequency === 'ANNUALLY' ? 'YEARLY' : this.state.frequency;
        const meta = {
            payment_frequency: paymentFrequency,
            for: 'sme',
            callback_url:  process.env.REACT_APP_CONGRATS_URL || 'https://client.staging.reliancehmo.com/dashboard/congratulations',
            client: {
                id: user.preferences.selectedClient
            }
        };

        const request = {
            cart: this.state.cart,
            meta
        };

        clientService.purchasePlan(JSON.stringify(request), user.credentials.accessToken).then(
            (response) => {
                if (response.authorization_url !== '') {
                    window.location.href = response.authorization_url;
                } else {
                    this.props.history.push('/dashboard/invoices');
                }
            },
            (error) => {
                this.setState({
                    error: {
                        message: error.response.data.message
                    }
                });
            }
        );
    };

    toggleFamilyOption = (plan, frequency) => {
        let familyIndex = this.state.familyOption.findIndex(item => JSON.stringify(item) === JSON.stringify({...plan, frequency})) 
        if (familyIndex >= 0) {
            this.setState({
                familyOption: [
                    ...this.state.familyOption.slice(0, familyIndex), 
                    ...this.state.familyOption.slice(familyIndex + 1)
                ]
            })
            return
        } else {
            this.setState({
                familyOption: [...this.state.familyOption, { ...plan, frequency}]
            })
        }
      
    };

    showFamilyOptionModal = () => {
        this.setState({
            showFamilyOptionModal: true
        });
    };

    clearErrorMessage = () => {
        this.setState({
            errorMessage: {
                text: '',
                id: ''
            }
        });
    };

    toggleTermsCheckbox = () => {
        this.setState({
            termsAndConditionsCheckbox: !this.state.termsAndConditionsCheckbox
        });
    };

    togglePlansExclusionCheckBox = () => {
        this.setState({
            plansExclusionCheckbox: !this.state.plansExclusionCheckbox
        });
    };

    toggleBenefitCheckbox = () => {
        this.setState({
            benefitsCheckbox: !this.state.benefitsCheckbox
        });
    };

    price = (plan) => {
        return plan.price[`${this.state.frequency.toLowerCase()}`]
    }

    increasePlanCount = (id, unitPrice) => {
        if (1 + this.state.cart.reduce((sum, plan) => sum + plan.no_of_enrollees, 0) > 19) {
        this.setState({
            errorMessage: {
                text: 'A maximum of 19 employees can be added on a small business plan.',
                id: id
            }
        });
        return
    }
    
        const newCart = this.state.cart.map(item => 
            item.product_id === id ? { 
                ...item, 
                no_of_enrollees: item.no_of_enrollees + 1,
                price: unitPrice * (item.no_of_enrollees + 1)
            } : item
          );
        this.setState({
            cart: newCart
        })
    }

    decreasePlanCount = (id, count, unitPrice) => {
        if (count - 1 === 0) {
            this.removeSingleItem(id)
            return
        }
        const newCart = this.state.cart.map(item => 
            item.product_id === id ? {
                 ...item, 
                 no_of_enrollees: item.no_of_enrollees - 1,
                 price: unitPrice * (item.no_of_enrollees - 1)
                } : item
          );
        
        this.setState({
            cart: newCart
        })
    }

    render() {
        const allPlans = [...this.state.plans];
        const individualPlanList = allPlans.filter((plan) => plan.type === 'Individual');
        const familyPlanList = allPlans.filter((plan) => plan.type === 'Family')
           
        const enrolleeCount = () =>
            this.state.cart.reduce((sum, plan) => sum + plan.no_of_enrollees, 0);

        return (
            <div className="plan-purchase-wrapper">
                <MainContent>
                    <PageHeader title="Buy a New Health Plan" />
                    <br />
                    <PageBody>
                        <div>
                            {this.state.cart.length > 0 && (
                                <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <div className="Cart-wrapper">
                                            <h2>
                                                {this.state.cart.reduce(
                                                    (sum, plan) => sum + plan.no_of_enrollees,
                                                    0
                                                )}
                                                Item(s) added in Cart
                                            </h2>
                                            <div className="cart-summary-btn-row">
                                            <button
                                                className="remove-all-btn"
                                                onClick={this.removeAll}
                                            >
                                                Remove all
                                            </button>
                                            <button
                                                className="primary_button checkout-btn"
                                                onClick={this.showPurchaseModal}
                                                disabled={enrolleeCount() < 3}
                                            >
                                                Checkout
                                            </button>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.state.cart.map((plan) => {
                                            return (
                                                <div className="Cart-items-wrapper">
                                                    <h3>
                                                        {plan.name?.split('-')[0]} -{' '}
                                                        {plan.name.includes('-Ind')
                                                            ? '(Individual Plan)'
                                                            : '(Family Plan)'}{' '}
                                                        - {plan.no_of_enrollees}X
                                                    </h3>
                                                    <p className="cart-summary-text">N{new Intl.NumberFormat().format(plan.price)} 

                                                        <p>|</p> 
                                                    <i
                                                        type="Button"
                                                        className="fa fa-trash trash-icon"
                                                        onClick={() =>
                                                            this.removeSingleItem(plan.product_id)
                                                        }
                                                    ></i>
                                                    </p>
                                                </div>
                                            );
                                        })}
                                      <div className="cart-summary-subtotal">
                                      <h2 className="plan-card-price">
                                        Total - N{new Intl.NumberFormat().format(this.state.cart.reduce((sum, plan) => sum + plan.price, 0))}
                                      </h2>
                                      </div>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </div>
                        <div className="payment-frequency-wrapper">
                            <h2>Select a payment plan</h2>
                            <div className="payment-frequency-row">
                                <p
                                    className={`frequency-select ${
                                        this.state.frequency === 'quarterly'
                                            ? 'active-frequency'
                                            : ''
                                    }`}
                                    onClick={() => this.handleChangeFrequency('quarterly')}
                                >
                                    Quarterly
                                </p>
                                <p
                                    className={`frequency-select ${
                                        this.state.frequency === 'biannually'
                                            ? 'active-frequency'
                                            : ''
                                    }`}
                                    onClick={() => this.handleChangeFrequency('biannually')}
                                >
                                    Bi-Annually - <span className="discount">15% Discount</span>
                                </p>
                                <p
                                    className={`frequency-select ${
                                        this.state.frequency === 'yearly'
                                            ? 'active-frequency'
                                            : ''
                                    }`}
                                    onClick={() => this.handleChangeFrequency('yearly')}
                                >
                                    Annually - 20% Discount
                                </p>
                            </div>
                        </div>
                        <div className="notification-alert-row">
                            <Alert
                                icon={
                                    <InfoOutlinedIcon fontSize="inherit" sx={{ color: 'black' }} />
                                }
                                severity="warning"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    borderRadius: '5px',
                                    fontSize: '12px',
                                    color: 'black'
                                }}
                            >
                                To purchase plans that cover family members, click the “Include
                                Family” button
                            </Alert>
                        </div>

                        <div className="plan-card-section">
                            {this.state.loading && <LoadingScreen />}
                            {individualPlanList.map((plan, index) => {
                                return (
                                    <PlanCard
                                        individualPrice={this.price(plan)}
                                        familyPrice={this.price(familyPlanList[index])}
                                        name={plan.name}
                                        AddToCart={this.AddToCart}
                                        id={plan.id}
                                        familyOption={this.state.familyOption}
                                        toggleFamilyOption={() =>
                                            this.toggleFamilyOption(plan, this.state.frequency)
                                        }
                                        error={this.state.errorMessage}
                                        itemInCart={
                                            this.state.cart.findIndex(
                                                (cartItem) => (cartItem.product_id === plan.id || cartItem.product_id === familyPlanList[index].id)
                                            ) >= 0
                                        }
                                        cart={this.state.cart}
                                        frequency={this.state.frequency}
                                        key={plan.id}
                                        increaseCount={this.increasePlanCount}
                                        decreaseCount={this.decreasePlanCount}
                                        totalItemsInCart={this.state.cart.reduce((sum, plan) => sum + plan.no_of_enrollees, 0)}
                                        benefit={benefits[index]}
                                        familyVariant={familyPlanList[index]}
                                        plan={plan}
    
                                    />
                                );
                            })}
                        </div>
                        {this.state.showFamilyOptionModal && (
                            <SwitchFamilyOptionModal
                                onClose={() =>
                                    this.setState({
                                        showFamilyOptionModal: false
                                    })
                                }
                                clearCart={this.removeAll}
                                toggleFamilyOption={() => this.toggleFamilyOption()}
                            />
                        )}
                        {this.state.showSwichFrequencyModal && (
                            <SwitchPaymentFrequencyModal
                                onClose={this.hideSwitchPaymentFrequencyModal}
                                clearCart={this.removeAll}
                                frequency={this.state.switchFrequencyValue}
                                switchFrequency={(frequency) =>
                                    this.setState({
                                        frequency
                                    })
                                }
                            />
                        )}

                        {this.state.showPurchaseModal && (
                            <PlanPurchaseModal
                                plans={this.state.cart || []}
                                onClose={this.hidePurchaseModal}
                                onCheckout={this.onCheckout}
                                frequency={this.state.frequency}
                                togglePlansExclusionCheckBox={this.togglePlansExclusionCheckBox}
                                toggleTermsCheckbox={this.toggleTermsCheckbox}
                                termsAndConditionsChecked={this.state.termsAndConditionsCheckbox}
                                toggleBenefitCheckbox={this.toggleBenefitCheckbox}
                                planExclusionChecked={this.state.plansExclusionCheckbox}
                                benefitsChecked={this.state.benefitsCheckbox}
                                closeError={this.clearErrorMessage}
                            />
                        )}
                        {this.state.errorMessage.text && (
                            <ErrorComponent
                                close={this.clearErrorMessage}
                                errorMessage={this.state.errorMessage.text}
                                clearErrorMessage={this.clearErrorMessage}
                            />
                        )}
                    </PageBody>
                </MainContent>
            </div>
        );
    }
}

const SwitchFamilyOptionModal = ({ clearCart, onClose, toggleFamilyOption }) => {
    return (
        <Modal title="" onClose={onClose}>
            <div className="switch-modal-wrapper">
                {/* <i className="fa-thin fa-info-circle"></i> */}
                <h2>Your are about to switch back to "Individual Plans"</h2>
                <p>Switching back to individual plans automatically clears your current cart</p>
                <div className="switch-modal-buttons-row">
                    <button
                        className="primary_button add-to-cart-btn switch-modal-button-cancel"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="primary_button add-to-cart-btn switch-modal-button"
                        onClick={() => {
                            clearCart();
                            onClose();
                            toggleFamilyOption();
                        }}
                    >
                        Yes, Switch
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const SwitchPaymentFrequencyModal = ({ clearCart, onClose, frequency, switchFrequency }) => {
    return (
        <Modal title="" onClose={onClose}>
            <div className="switch-modal-wrapper">
                {/* <i className="fa fa-info-circle"></i> */}
                <h2>Your are about to change your payment plan</h2>
                <p>
                    Changing your payment plan means that all items in your basket will be
                    automatically removed to allow plans with new billing frequency to be added. If
                    yes, kindly click on the Accept button.
                </p>
                <div className="switch-modal-buttons-row">
                    <button
                        className="primary_button add-to-cart-btn switch-modal-button-cancel"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="primary_button add-to-cart-btn switch-modal-button"
                        onClick={() => {
                            clearCart();
                            onClose();
                            switchFrequency(frequency);
                        }}
                    >
                        Yes, Switch
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const PlanPurchaseModal = ({
    plans,
    onClose,
    onCheckout,
    frequency,
    toggleTermsCheckbox,
    togglePlansExclusionCheckBox,
    termsAndConditionsChecked,
    benefitsChecked,
    planExclusionChecked,
    toggleBenefitCheckbox,
    closeError
}) => {
    return (
        <Modal title="" onClose={onClose}>
            <div className="plan-purchase-modal-body">
                <h2>Plan Purchase Summary</h2>
                <p>Take another quick look to make sure everything is spot on</p>
                <div className="plan-purchase-items-list">
                    <div className="plan-purchase-items-list-header">
                        <p>Plan Name</p>
                        <p>Quantity</p>
                    </div>
                    {plans?.map((plan) => (
                        <div className="plan-purchase-items-list-row">
                            <p>
                                {plan.name?.split('-')[0]} (
                                {plan.name.includes('-Ind') ? 'Individual' : 'Family'}){' '}
                            </p>
                            <p>{plan?.no_of_enrollees}</p>
                        </div>
                    ))}

                    <hr />
                    <div className="plan-purchase-items-list-row">
                        <p>Payment Frequency</p>
                        <p>{frequency}</p>
                    </div>
                    <hr />
                    <div className="total-price-row">
                        <p>Total Amount</p>
                        <h2 className="plan-card-price">
                            N
                            {new Intl.NumberFormat().format(
                                plans.reduce((sum, plan) => sum + plan.price, 0)
                            )}
                        </h2>
                    </div>
                    <div className="exclusions-section">
                        <h4>What this plan doesn't cover</h4>
                        <h5>Individual plans:</h5>
                        <ul>
                            <li>
                                This plan is only available to individuals under 65 years of age
                            </li>
                        </ul>
                        <h5>For the family plans:</h5>
                        <ul>
                            <li>Spouses must be under 65 years old</li>
                            <li>Children must be under 24 years to qualify</li>
                        </ul>
                        <Link to={process.env.REACT_APP_SME_EXCLUSIONS || '/'}>
                            {' '}
                            Learn more about Exclusions.
                        </Link>
                    </div>
                    <div className="terms-section">
                        <div>
                            <input
                                type="checkbox"
                                checked={planExclusionChecked}
                                onClick={togglePlansExclusionCheckBox}
                            />
                            <label>
                                I agree to Reliance Health Data and Privacy Policy&nbsp;
                                <a href={process.env.REACT_APP_SME_PRIVACY_POLICY} target="_blank">
                                    Protecting Your Privacy and Personal Info | Small Business
                                </a>
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                checked={termsAndConditionsChecked}
                                onClick={toggleTermsCheckbox}
                            />
                            <label>
                                I agree to RELIANCE HEALTH’s Small Business&nbsp;
                                <a href={process.env.REACT_APP_SME_SLA} target="_blank">
                                    Service Level Agreement
                                </a>
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                checked={benefitsChecked}
                                onClick={toggleBenefitCheckbox}
                            />
                            <label>
                                I have read the&nbsp;
                                <a href={process.env.REACT_APP_SME_HEALTH_BENEFITS} target="_blank">
                                    Comprehensive List of Health Benefits
                                </a>
                            </label>
                        </div>
                    </div>
                </div>
                {/* {(!termsAndConditionsChecked || !planExclusionChecked) && (
                {/* {(!termsAndConditionsChecked || !planExclusionChecked) && (
                    <div className="plan-modal-error-component">
                        <div>
                            <h4>Error</h4>
                            <p>Tick all boxes to proceed.</p>
                        </div>
                        <div onClick={closeError}>x</div>
                    </div>
                )} */}
                <div className="plan-summary-modal-buttons-row">
                    <button
                        className="primary_button add-to-cart-btn switch-modal-button-cancel"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="primary_button add-to-cart-btn switch-modal-button"
                        onClick={onCheckout}
                        disabled={
                            !termsAndConditionsChecked || !planExclusionChecked || !benefitsChecked
                        }
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </Modal>
    );
};

const ErrorComponent = ({ errorMessage, close, clearErrorMessage }) => {
    if (errorMessage) {
        setTimeout(clearErrorMessage, 5000)
    }
    return (
        <div className="error-component-wrapper">
            <div>
                <h4>Error</h4>
                <p>{errorMessage}</p>
            </div>
            <div onClick={close}>x</div>
        </div>
    );
};
export default withRouter(PlanPurchase);
