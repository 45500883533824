import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';

class PlanCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            individualEmployeeCount: 0,
            familyEmployeeCount: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.frequency !== this.props.frequency) {
            // ✅ Reset count on frequency change
            this.setState({
                individualEmployeeCount: 0,
                familyEmployeeCount: 0
            });
        }
    }

    increaseCount = () => {
        const { individualPrice, cart, id, familyVariant, AddToCart, name  } = this.props
        const { individualEmployeeCount } = this.state;

        const itemExistInCart = cart.findIndex(cartItem => cartItem.product_id === id) >= 0
        const familyVariantExistInCart = cart.findIndex(cartItem => cartItem.product_id === familyVariant.id) >= 0

        this.props.increaseCount(this.props.id, individualPrice);
        if (this.props.totalItemsInCart + 1 > 19) return;

        if(familyVariantExistInCart && !itemExistInCart) {
            const individualItem = {
                no_of_enrollees: individualEmployeeCount + 1,
                price: individualPrice * (individualEmployeeCount + 1),
                name: name,
                product_id: id
            };
            AddToCart(individualItem)

        }
        this.setState({
            individualEmployeeCount: this.state.individualEmployeeCount + 1
        });
    };

    decreaseCount = () => {
        const { individualPrice } = this.props;
        this.props.decreaseCount(this.props.id, this.state.individualEmployeeCount, individualPrice);
        if (this.state.individualEmployeeCount === 0) return;
        this.setState({
            individualEmployeeCount: this.state.individualEmployeeCount - 1
        });
    };

    increaseFamilyCount = () => {
        const { cart, id, familyVariant, familyPrice, name, AddToCart } = this.props;
        const { familyEmployeeCount } = this.state;

        const itemExistInCart = cart.findIndex(cartItem => cartItem.product_id === familyVariant.id) >= 0
        const individualVariantExistInCart = cart.findIndex(cartItem => cartItem.product_id === id) >= 0

        this.props.increaseCount(this.props.familyVariant.id, familyPrice);
        if (this.props.totalItemsInCart + 1 > 19) return;

        if (individualVariantExistInCart && !itemExistInCart) {
            const familyItem = {
                no_of_enrollees: familyEmployeeCount + 1,
                price: familyPrice * (familyEmployeeCount + 1),
                name: familyVariant.name,
                product_id: familyVariant.id
            }
            AddToCart(familyItem)

        }
        this.setState({
            familyEmployeeCount: this.state.familyEmployeeCount + 1
        });
    };

    decreaseFamilyCount = () => {
        const { familyPrice } = this.props;
        this.props.decreaseCount(this.props.familyVariant.id, this.state.familyEmployeeCount, familyPrice);
        
        if (this.state.familyEmployeeCount === 0) return;
        this.setState({
            familyEmployeeCount: this.state.familyEmployeeCount - 1
        });
    };

    AddToCart = () => {
        const { individualEmployeeCount, familyEmployeeCount } = this.state;
        const { AddToCart, individualPrice, familyPrice, name, id, familyVariant, plan, frequency,  } = this.props;

        const cartUpdates = [];

        if (individualEmployeeCount > 0) {
            const individualItem = {
                no_of_enrollees: individualEmployeeCount,
                price: individualPrice * individualEmployeeCount,
                name: name,
                product_id: id
            };
            cartUpdates.push(individualItem);
        }

        if (familyEmployeeCount > 0 && this.isFamilyOptionChecked(plan, frequency)) {
            const familyItem = {
                no_of_enrollees: familyEmployeeCount,
                price: familyPrice * familyEmployeeCount,
                name: familyVariant?.name,
                product_id: familyVariant?.id
            };
            cartUpdates.push(familyItem);
        }

        // Add each item to the cart sequentially
        cartUpdates.forEach((item) => {
            setTimeout(() => {
                AddToCart(item);
            }, 200); 
        });
    };

    Price = (planPrice) => new Intl.NumberFormat().format(planPrice);
    isFamilyOptionChecked = (plan, frequency) => {
        let familyIndex = this.props.familyOption.findIndex(
            (item) => JSON.stringify(item) === JSON.stringify({ ...plan, frequency })
        );
        if (familyIndex >= 0) {
            return true;
        } else {
            return false;
        }
    };

    clearCart = () => {
        this.setState({
            individualEmployeeCount: 0,
            familyEmployeeCount: 0
        })
    }

    render() {
        const { individualEmployeeCount, familyEmployeeCount } = this.state;
        const {
            id,
            name,
            individualPrice,
            familyPrice,
            error,
            itemInCart,
            familyOption,
            toggleFamilyOption,
            frequency,
            benefit,
            familyVariant,
            plan,
            removeAll
        } = this.props;

        const show = () => {
            if (error.id === id || error.id === familyVariant.id) {
                return 'plan-card-error';
            }
        };

        return (
            <div className={`plan-card ${show()}`}>
                <div className="plan-card-header">
                    <h2 className="plan-card-name">{name?.split('-')[0]}</h2>
                    <div className="family-option-container">
                        <Switch
                            size="small"
                            label="Include family"
                            checked={this.isFamilyOptionChecked(plan, frequency)}
                            onClick={() => toggleFamilyOption(name)}
                        />{' '}
                        <label>Include Family</label>
                        <Tooltip
                            id="mui-tip"
                            arrow
                            title={
                                'Let employees add up to 5 family members, including spouse and children, to their health plans.'
                            }
                        >
                            <i className="fa fa-question-circle-o fa-1x" aria-hidden="true"></i>
                        </Tooltip>
                    </div>
                </div>
                <div></div>
                <div className="plan-card-footer">
                    <div className="plan-card-row">
                        <div>
                            {individualEmployeeCount > 0 ? (
                                <span className="plan-card-price-span">
                                    <h2 className="plan-card-price">
                                        N
                                        {new Intl.NumberFormat().format(
                                            individualPrice * individualEmployeeCount
                                        )}                                
                                    </h2>
                                    <p>/Year</p>
                                </span>
                            ) : (
                                <span className="plan-card-price-span">
                                    <h2 className="plan-card-price">
                                        N{this.Price(individualPrice)}
                                    </h2>
                                    <p>/{frequency.charAt(0).toUpperCase() + frequency.slice(1)}</p>
                                </span>
                            )}
                            Per employee
                        </div>
                        <div className="employee-count-box">
                            <button
                                type="button"
                                className="employee-count-button"
                                onClick={this.decreaseCount}
                            >
                                -
                            </button>
                            <p>{individualEmployeeCount}</p>
                            <button
                                type="button"
                                className="employee-count-button"
                                onClick={this.increaseCount}
                            >
                                +
                            </button>
                        </div>
                    </div>
                    {this.isFamilyOptionChecked(plan, frequency) && (
                        <div className="plan-card-row">
                            <div>
                                {familyEmployeeCount > 0 ? (
                                    <span className="plan-card-price-span">
                                        <h2 className="plan-card-price">
                                            N
                                            {new Intl.NumberFormat().format(
                                                familyPrice * familyEmployeeCount
                                            )}            
                                        </h2>
                                        <p>/Year</p>
                                    </span>
                                ) : (
                                    <span className="plan-card-price-span">
                                        <h2 className="plan-card-price">
                                            N{this.Price(familyPrice)}
                                        </h2>
                                        <p>
                                            /{frequency.charAt(0).toUpperCase() + frequency.slice(1)}
                                        </p>
                                    </span>
                                )}
                                Per family plan
                            </div>
                            <div className="employee-count-box">
                                <button
                                    type="button"
                                    className="employee-count-button"
                                    onClick={this.decreaseFamilyCount}
                                >
                                    -
                                </button>
                                <p>{familyEmployeeCount}</p>
                                <button
                                    type="button"
                                    className="employee-count-button"
                                    onClick={this.increaseFamilyCount}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="plan-details-card">
                        <p>What's Included:</p>
                        <ul>
                            <li>{benefit.benefit1}</li>
                            <li>{benefit.benefit2}</li>
                            <li>{benefit.benefit3}</li>
                            <li>{benefit.benefit4}</li>
                            <li>{benefit.benefit5}</li>
                            <li>{benefit.benefit6}</li>
                            <li>{benefit.benefit7}</li>
                            <li>{benefit.benefit8}</li>
                            <li>{benefit.benefit9}</li>
                            <li>{benefit.benefit10}</li>
                            {benefit.benefit11 && <li>{benefit?.benefit11}</li>}
                            {benefit.benefit12 && <li>{benefit?.benefit12}</li>}
                        </ul>
                    </div>
                    <div className={`plan-buttons-row ${name.includes('RED BERYL') ? 'plan-buttons-row-first' : ''}`}>
                        <button
                            className={`add-to-cart-btn ${
                                itemInCart ? 'btn-active' : ''
                            }`}
                            onClick={() => !itemInCart && this.AddToCart()}
                            disabled={individualEmployeeCount === 0 && familyEmployeeCount === 0}
                        >
                            {itemInCart ? `Added to Cart` : 'Add Plan'}
                            {itemInCart ? (
                                <i className="fa-light fa-check added-to-cart-icon"></i>
                            ) : (
                                ''
                            )}
                        </button>
                        <a
                            className="benefit-button" 
                            href={process.env.REACT_APP_SME_HEALTH_BENEFITS}
                            target="_blank"
                        >
                            View benefits List
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlanCard;
