import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';

import PageHeader from './boxes/PageHeader';
import MainContent from './boxes/MainContent';
import { CustomTabPanel } from './CustomTabPanel';
import SalesIQ from './ZohoChatbot';


import faqs from '../Faqs.json';

class HelpComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            searchKeyWord: ''
        };
    }

    a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    };

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        });
    };

    handleSearch = (event) => {
        this.setState({
            searchKeyWord: event.target.value
        })
    }

    handleClick = () => {
        // Open the Zoho chatbot
        if (window.$zoho && window.$zoho.salesiq) {
          window.$zoho.salesiq.floatwindow.open();
        } else {
          console.error('Zoho SalesIQ is not initialized');
        }
    }

    render() {
        return (
            <MainContent>
                <PageHeader title="Help" />
                <div className="help-section-wrapper">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={this.state.value}
                                onChange={this.handleChange}
                                aria-label="basic tabs example"
                                textColor="primary"
                                indicatorColor="primary"
                            >
                                <Tab 
                                    sx={{
                                    fontSize: '12px',
                                    textTransform: 'capitalize'
                                    }}
                                    label="Contact support" 
                                    {...this.a11yProps(0)} 
                                />
                                <Tab
                                    sx={{
                                        fontSize: '12px',
                                    }}
                                    label="Faq's" 
                                    {...this.a11yProps(1)} 
                                />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={this.state.value} index={0}>
                            <Box display="flex" gap={1.2}>
                                <Box
                                    sx={{
                                        width: '50%',
                                        background: 'white',
                                        borderRadius: '1rem',
                                        height: '16rem',
                                        textAlign: 'center',
                                        fontSize: '2rem',
                                        fontWeight: '500',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        gap: '10px'
                                    }}
                                >
                                    <div className="icon-container bg-pink" onClick={this.handleClick}>
                                        <HeadsetMicOutlinedIcon sx={{ fontSize: '36px '}} />
                                    </div>
                                    Chat with Us
                                </Box>
                                <Box
                                    sx={{
                                        width: '50%',
                                        background: 'white',
                                        borderRadius: '1rem',
                                        height: '16rem',
                                        textAlign: 'center',
                                        fontSize: '2rem',
                                        fontWeight: '500',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        gap: '10px'
                                    }}
                                >
                                    <div className="icon-container bg-blue">
                                    <a href="mailto:hellonigeria@getreliancehealth.com?subject=Client Dashboard Help[from our client]">
                                        <MailOutlineIcon 
                                            sx={{ fontSize: '36px' }}
                                        />
                                    </a>
                                    </div>
                                    Email us
                                </Box>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={this.state.value} index={1}>
                            <Box sx={{ background: 'white' }}>
                                <input 
                                    type="text"
                                    onChange={this.handleSearch} 
                                    placeholder="Search FAQ's"
                                    className="faq-search-input"
                                />
                                {faqs.map((faqItem) => {
                                if(faqItem.question.includes(this.state.searchKeyWord)) {
                                    return(
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography fontSize={14} fontWeight={500} component="span">
                                            {faqItem.question}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={14}>
                                           {faqItem.answer}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>)}
                                })}
                            </Box>
                        </CustomTabPanel>
                        <SalesIQ />
                    </Box>
                </div>
            </MainContent>
        );
    }
}

export default withRouter(HelpComponent);
